.about-us {
  padding: 100px 20px;

  &__title {
    margin-bottom: 40px;
    margin-top: 0;
  }

  &__sub-title {
    margin-bottom: 30px;
  }

  &__team-details {
    margin: 70px auto auto;

    &-detail {
      color: var(--color-blue);
      margin-right: 80px;

      &:last-child {
        margin-right: 0;
      }
    }

    &-number {
      margin-right: 15px;

      &.--single {
        margin-right: 0;
      }
    }
  }

  &__contact-property {
    text-align: center;

    a {
      color: var(--color-blue);

      &:hover {
        img {
          transform: scale(1.2);
        }
      }
    }

    li {
      display: inline-block;
      width: 220px;

      img {
        display: block;
        margin: 0 auto;
        transition: all .3s ease;
      }
    }
  }

  p {
    margin: 25px 0;
  }
}

@include media-mobile-query {
  .about-us {
    padding: 50px 20px;

    &.--contact {
      padding-top: 0;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__sub-title {
      margin-bottom: 10px;
    }

    &__team-details {
      max-width: 425px;

      &-number {
        font-size: 36px !important;
        line-height: 48px !important;
        margin-right: 0;
      }

      &-detail {
        color: var(--color-blue);
        margin-right: 40px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__contact-property {
      margin-top: 50px;

      li {
        padding-bottom: 40px;
      }
    }

    p {
      margin: 0;
    }
  }
}
