@mixin media-mobile-query {
  @media screen and (max-width: 767px) {
    @content
  }
}

@mixin media-retina-breakpoint() {
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 2dppx) {
    @content;
  }
}