.book-demo {
  max-width: 940px;
  margin: 0 auto;
  padding: 70px;
  border-radius: 4px;
  text-align: center;
  background-color: var(--color-blue-light);

  &__title {
    margin-bottom: 20px;
  }

  &__text {
    margin-bottom: 50px;
  }

  &__button {
    max-width: 220px;
  }
}

@include media-mobile-query() {
  .book-demo {
    padding: 50px 20px;

    &__text {
      margin-bottom: 30px;
    }

    &__button {
      max-width: 210px;
      font-size: 20px;
      height: 50px;
    }
  }
}
