.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-start {
  justify-content: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-center {
  justify-content: center;
}

.flex-top {
  align-items: flex-start;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.column-gap {
  column-gap: 15px;
}

@include media-mobile-query() {
  .xs-center {
    justify-content: center;
  }

  .xs-column {
    flex-direction: column;

    &.xs-center {
      align-items: center;
    }
  }
}

