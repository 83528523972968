.gallery {
  position: relative;
  overflow: hidden;

  &__images {
    transition: transform 400ms ease 0s;
  }

  &__img-container {
    width: 300px;
    margin-right: 20px;

    img {
      cursor: pointer;
      border-radius: 6px;
    }
  }

  &__prev, &__next {
    transform: translateY(-50%);
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--color-white);
    top: 50%;
    outline: none;
    transition: opacity .3s ease-out;
    border: none;
    cursor: pointer;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    &::before {
      content: '';
      top: 50%;
      left: 50%;
      width: 24px;
      height: 24px;
      position: absolute;
      transform: translate(-50%, -50%);
      background: url('../assets/images/svg/ic-arrow.svg') no-repeat;
    }

    &:hover {
      background-color: var(--color-blue);

      &::before {
        background: url('../assets/images/svg/ic-arrow-white.svg') no-repeat;
      }
    }
  }

  &__prev {
    left: 20px;
  }

  &__next {
    right: 20px;

    &::before {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
}


@include media-mobile-query() {
  .gallery {
    &__img-container {
      width: 190px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__prev, &__next {
      width: 50px;
      height: 140px;
      background-color: transparent;
      border: none;

      &::before {
        background: url('../assets/images/svg/ic-arrow-white.svg') no-repeat;
      }

      &:hover {
        background-color: transparent;
      }
    }

    &__prev {
      left: 10px;
    }

    &__next {
      right: 10px;
    }
  }
}
