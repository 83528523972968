* {
  box-sizing: border-box;
  font-family: var(--proxima_regular)
}

a, a:hover, a:focus, a:active, a:link {
  text-decoration: none;
  color: inherit;
}

html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

body { min-width: 980px }
.container { padding: 0 20px }
.container-small { max-width: 660px; margin: 0 auto; padding: 0 20px }
.container-middle { max-width: 960px; margin: 0 auto; padding: 0 20px }
.only-xs { display: none; }

@include media-mobile-query() {
  body { min-width: 320px }

  .only-md {
    display: none;
  }

  .only-xs {
    display: block;
  }
}
