:root {
  --proxima_bold: 'Proxima_Nova_Bold', sans-serif;
  --proxima_regular: 'Proxima_Nova_Regular', sans-serif;

  font-family: var(--proxima_regular);
}

.font-xl {
  font-size: 72px;
  line-height: 72px;

  &-b {
    font-size: 72px;
    line-height: 72px;
    font-family: var(--proxima_bold);
  }
}

.font-lg {
  font-size: 48px;
  line-height: 48px;

  &-b {
    font-size: 48px;
    line-height: 48px;
    font-family: var(--proxima_bold);
  }
}

.font-sm-bigger {
  font-size: 24px;
  line-height: 28px;

  &-b {
    font-size: 24px;
    line-height: 28px;
    font-family: var(--proxima_bold);
  }
}

.font-sm {
  font-size: 20px;
  line-height: 28px;

  &-b {
    font-size: 20px;
    line-height: 28px;
    font-family: var(--proxima_bold);
  }
}

.font-xs-bigger {
  font-size: 16px;
  line-height: 22px;

  &-b {
    font-size: 16px;
    line-height: 22px;
    font-family: var(--proxima_bold);
  }
}

.font-xs {
  font-size: 14px;
  line-height: 20px;

  &-b {
    font-size: 14px;
    line-height: 20px;
    font-family: var(--proxima_bold);
  }
}

//font-colors
.font-white {
  color: var(--color-white) !important;
}

.text-center {
  text-align: center;
}

@include media-mobile-query() {
  .font-xl {
    font-size: 20px;
    line-height: 28px;

    &-b {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .font-lg {
    font-size: 20px;
    line-height: 28px;

    &-b {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .font-sm-bigger {
    font-size: 20px;
    line-height: 28px;

    &-b {
      font-size: 20px;
      line-height: 28px;
      font-family: var(--proxima_bold);
    }
  }

  .font-sm {
    font-size: 16px;
    line-height: 22px;

    &-b {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .text-left-xs {
    text-align: left;
  }
}
