@import '../../node_modules/swiper/swiper.min.css';
@import '../../node_modules/swiper/modules/navigation/navigation.min.css';
@import '../../node_modules/swiper/modules/pagination/pagination.min.css';

.swiper {
  height: 100%;

  &-slide {
    display: flex;
    justify-content: center;
    position: relative;

    img {
      max-width: 900px;
      max-height: 90%;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      top: 0;
    }
  }

  &-pagination {
    color: var(--color-white);
    font-size: 28px;
    line-height: 28px;

    &-current {
      font-size: 48px;
      line-height: 48px;
      font-weight: 700;
    }
  }

  &-button-prev, &-button-next {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--color-white);
    top: 50%;
    transform: translateY(-30%);
    outline: none;
    transition: opacity .3s ease-out;
    border: none;
    cursor: pointer;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    &::after {
      content: '';
    }

    &::before {
      content: '';
      top: 50%;
      left: 50%;
      width: 24px;
      height: 24px;
      position: absolute;
      transform: translate(-50%, -50%);
      background: url('../assets/images/svg/ic-arrow.svg') no-repeat;
    }

    &:hover {
      background-color: var(--color-blue);

      &::before {
        background: url('../assets/images/svg/ic-arrow-white.svg') no-repeat;
      }
    }
  }

  &-button-next {
    &::before {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
}

@include media-mobile-query() {
  .swiper {
    &-button-prev, &-button-next {
      width: 50px;
      height: 140px;
      background-color: transparent;
      border: none;

      &::before {
        background: url('../assets/images/svg/ic-arrow-white.svg') no-repeat;
      }

      &:hover {
        background-color: transparent;
      }
    }

    &-slide {
      img {
        max-width: 100%;
      }
    }
  }
}
