@import "../media-query";

.terms-policy {
  padding-bottom: 70px;

  h1 {
    margin: 83px 0 72px;
  }

  h3 {
    margin: 88px 0 25px;
  }

  p {
    margin: 25px 0;
  }
}

@include media-mobile-query() {
  .terms-policy {
    padding-bottom: 40px;

    h1 {
      margin: 20px 0 10px;
    }

    h3 {
      margin: 50px 0 10px;
    }

    p {
      margin: 10px 0;
    }
  }
}
