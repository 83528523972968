.short-demo-bg {
  overflow: hidden;
  position: relative;

  &__img {
    position: absolute;
    left: calc(50% - 190px);
    top: -68px;
    z-index: -1;
  }
}

.short-demo {
  max-width: 780px;
  padding-top: 358px;
  padding-bottom: 150px;
  margin: 0 auto;

  &__title {
    max-width: 270px;
  }

  &__video {
    margin: 50px 0;
  }

  &__text {
    max-width: 460px;
  }
}

@include media-mobile-query() {
  .short-demo-bg {
    &__img {
      width: 820px;
      height: auto;
      position: relative;
      left: calc(50% - 281px);
      top: -65px;
      z-index: auto;
      margin-bottom: -263px;
    }
  }

  .short-demo {
    padding-top: 148px;
    padding-bottom: 0;

    &__content {
      margin: 0 auto;
    }

    &__title {
      text-align: center;
      max-width: none;
    }

    &__video {
      margin: 10px auto 20px;
    }

    &__text {
      max-width: 270px;
      margin: 0 auto;
      text-align: center;
    }
  }
}
