.badge {
  display: inline-block;
  background-color: var(--color-green);
  color: var(--color-white);
  padding: 3px 10px 2px 10px;
  border-radius: 15px;

  font-size: 14px;
  line-height: 20px;
  font-family: var(--proxima_regular);
}