.btn {
  cursor: pointer;
  padding: 7px 10px 10px;
  outline: transparent;
  width: 100%;
  max-width: 112px;
  height: 34px;
  background: none;
  color: var(--color-white);
  border: 1px solid var(--color-white);
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;

  transition-property: all;
  transition-duration: 300ms;

  &:hover {
    color: var(--color-orange);
    border-color: var(--color-orange);
  }
}

.btn {
  &-big {
    cursor: pointer;
    padding: 10px;
    outline: transparent;
    width: 100%;
    max-width: 300px;
    height: 60px;
    background: none;
    color: var(--color-white);
    border: 1px solid var(--color-white);
    border-radius: 5px;
    font-size: 24px;
    font-family: var(--proxima_bold);
    line-height: 28px;

    transition-property: all;
    transition-duration: 300ms;
  }

  &-medium {
    font-family: var(--proxima_bold);
    cursor: pointer;
    outline: transparent;
    height: 60px;
    color: var(--color-blue-dark);
    background-color: var(--color-white);
    border: none;
    border-radius: 29px;
    font-size: 16px;
    line-height: 22px;

    transition-property: all;
    transition-duration: 300ms;
  }

  &-orange {
    border-color: var(--color-orange-light);
    background-color: var(--color-orange-light);
  }

  &-blue {
    border-color: var(--color-blue);
    background-color: var(--color-blue);
  }
}

@include media-mobile-query() {
  .btn-medium {
    height: 40px;
    font-size: 14px;
    line-height: 20px;
  }
}

