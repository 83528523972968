.header {
  position: relative;
  z-index: 10;
  width: 100%;
  padding-top: 20px;
  background-color: var(--color-blue-dark);
  background-position: center bottom;
  background-size: 1400px 780px, 100% 780px;
  background-repeat: no-repeat;
  background-image: url("../assets/images/banner-main-bg.webp"), var(--main-bg);

  &.--common {
    background-image: none;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__logo {
    padding: 0 8px;
  }

  &__buttons {
    width: 100%;
    max-width: 243px;
    margin-bottom: 4px;

    &-link {
      width: 100%;
      max-width: 112px;
    }
  }
}

@include media-mobile-query() {
  .header {
    padding-top: 5px;
    background-repeat: no-repeat;
    background-size: 146%;
    background-color: var(--color-blue-dark);

    &.--common {
      background-image: none;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

@include media-retina-breakpoint() {
  .header {
    background-image: url("../assets/images/banner-main-bg@2x.webp"), var(--main-bg);
  }
}
