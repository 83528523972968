.banner {
  position: relative;
  height: 717px;

  &__wrapper {
    max-width: 780px;
    margin: 0 auto;
    padding-top: 52px;
    text-align: center;
  }

  &__title {
    margin: 30px 0;
  }

  &__text {
    max-width: 620px;
    padding-bottom: 50px;
    margin: 0 auto;
  }
}

@include media-mobile-query() {
  .banner {
    height: 486px;

    &__wrapper {
      padding-top: 36px;
    }

    &__title {
      padding: 0 28px;
      margin-bottom: 10px;
    }

    &__text {
      padding-bottom: 29px;
    }
  }
}
