.video {
  position: absolute;
  bottom: -209px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  width: 100%;
  max-width: 620px;
  height: 350px;
  border-radius: 4px;
  box-shadow: var(--shadow);

  background-color: var(--color-white);
  background-position: center bottom;
  background-size: cover;
  background-image: url("../assets/images/video-mock.png");

  &__img {
    margin: auto;
  }
}

@include media-mobile-query() {
  .video {
    bottom: -99px;
    max-width: 280px;
    height: 158px;

    &__img {
      width: 60px;
      height: auto;
    }
  }
}

@include media-retina-breakpoint() {
  .video {
    background-image: url("../assets/images/video-mock@2x.png");
  }
}
