:root {
  --color-blue-dark: #1F4AAA;
  --color-blue: #2558CB;
  --color-blue-light: #F2F6FD;
  --color-orange: #EF9300;
  --color-orange-light: #FFA617;
  --color-green: #0ABE6D;
  --color-grey: #EBEDF0;
  --color-white: #fff;

  --main-bg: linear-gradient(180deg, var(--color-blue-dark) 0%, var(--color-blue) 100%);
  --grey-bg: #f8f9fa;

  --shadow: #131519 0 2px 5px -3px;
}

.bg-grey {
  background-color: var(--grey-bg);
}

.bg-map {
  background: url("../assets/images/world-map.png") 50% no-repeat;
}

@include media-mobile-query() {
  :root {
    --main-bg: linear-gradient(180deg, var(--color-blue-dark) 0%, var(--color-blue-dark) 100%);
  }
}
