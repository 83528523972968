.footer {
  position: relative;
  padding: 92px 0;
  background-color: var(--color-blue);
  height: 360px;
  overflow: hidden;

  &__links {
    color: var(--color-white);

    a {
      &::after {
        content: "•";
        padding-left: 4px;
      }

      &:last-child {
        &::after {
          content: "";
          padding: 0;
        }
      }
    }
  }

  &__img {
    position: absolute;
    bottom: -59px;
    width: 380px;
  }

  &__wrapper {
    width: 100%;
    max-width: 940px;
    margin: 0 auto;
  }

  &__content {
    margin-left: auto;
    width: 100%;
    max-width: 540px;
  }

  &__socials {
    padding-bottom: 28px;
    padding-top: 28px;
    margin-bottom: 30px;
    border-bottom: 1px solid var(--color-blue-dark);

    &-item {
      margin-top: 2px;
      margin-right: 20px;
    }
  }

  &__text {
    margin-bottom: 9px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@include  media-mobile-query() {
  .footer {
    height: 599px;
    padding: 50px 0;

    &__wrapper {
     display: flex;
     flex-direction: column-reverse;
     align-items: center;
     padding: 0 20px;
    }

    &__img {
      position: relative;
      top: 0;
      left: 0;
    }

    &__text {
      margin-bottom: 19px;
      max-width: 190px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
    }

    &__socials {
      width: 285px;
      justify-content: center;

      &-item {
        margin: 2px 10px 0 10px;
      }
    }
  }
}
