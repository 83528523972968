.floorly {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;

  &__title {
    padding-bottom: 20px;
  }

  &__text {
    max-width: 780px;
    margin: 0 auto;
  }

  &__content {
    padding-top: 118px;
    max-width: 940px;
    margin: 0 auto;
    text-align: left;

    &-item {
      margin-bottom: 138px;

      &.--review {
        margin-bottom: 171px;
      }

      &.--rent {
        margin-bottom: 200px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-img {
      max-width: 380px;
      width: 100%;
      height: auto;

      &.--keep {
        max-width: 421px;
        padding-right: 40px;
      }
    }

    &-title {
      margin-bottom: 20px;
    }

    &-description {
      max-width: 380px;
    }
  }
}

@include media-mobile-query() {
  .floorly {
    padding-top: 50px;
    padding-bottom: 50px;

    &__content {
      padding-top: 51px;

      &-item {
        justify-content: center;
        margin-bottom: 47px;

        &.--review {
          margin-bottom: 50px;
        }

        &.--rent {
          margin-bottom: 49px;
        }
      }

      &-img {
        padding-bottom: 21px;

        &.--keep {
          padding-right: 0;
        }
      }

      &-title {
        margin-bottom: 10px;
      }
    }

    &__title {
      padding-bottom: 10px;
    }
  }
}