.worldwide {
  padding: 100px 0 80px 0;
  background-color: var(--color-blue);
  text-align: center;

  &__wrapper {
    max-width: 960px;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 20px;
  }

  &__buttons {
    padding-top: 50px;
  }

  &__button {
    cursor: auto;
    margin: 0 10px 20px;
    padding: 0 40px;
    border: 2px solid var(--color-white);
  }
}

@include media-mobile-query() {
  .worldwide {
    padding: 50px 0 40px;

    &__title {
      margin-bottom: 10px;
    }

    &__buttons {
      padding-top: 30px;
    }

    &__button {
      margin: 0 5px 10px;
    }
  }
}
