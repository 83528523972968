.disclaimer {
  max-width: 873px;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  padding: 50px 40px 0;

  &__title {
    text-align: center;
    margin-bottom: 10px;
  }

  &__text {
    color: rgba(244, 245, 247, .7);
  }
}

@include media-mobile-query() {
  .disclaimer {
    padding: 50px 0 0;

    &__title {
      font-size: 24px;
    }
  }
}