.modal {
  position: fixed;
  overflow: auto;
  display: flex;
  align-items: center;
  z-index: 99;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  background-color: var(--color-blue-dark);

  .wrapper {
    width: 100%;
    max-width: 142vh;
    padding: 50px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
  }

  .close-btn {
    border: 2px solid var(--color-blue);
    position: absolute;
    top: 30px;
    right: 40px;

    background-color: #fff;
    height: 50px;
    width: 50px;
    border-radius: 50%;

    transition-property: all;
    transition-duration: 200ms;

    z-index: 999;

    &:before {
      position: absolute;
      top: 1px;
      left: 0;
      content: '';
      min-width: 100%;
      min-height: 100%;
      -webkit-mask-image: url('../assets/images/svg/close.svg');
      mask-image: url('../assets/images/svg/close.svg');
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      background-color: var(--color-blue);
    }

    &:hover {
      cursor: pointer;
      background-color: var(--color-blue);

      &:before {
        background-color: var(--color-white);
      }
    }
  }

  .iframeContainer {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    margin-top: 60px;

    &.portrait {
      padding-bottom: calc(16/9 * 100%);
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 14px 24px 0 #2e2d3680;
    border-radius: 6px;
  }
}

@include media-mobile-query() {
  .modal {
    .wrapper {
      padding: 50px 20px 20px 20px;
    }

    .close-btn {
      top: 5px;
      right: 5px;
      border: none;
      background-color: transparent;
      border-radius: 0;

      &:before {
        background-color: var(--color-white);
      }
    }

    .iframeContainer {
      margin-top: 0;
    }
  }
}

@media (orientation: landscape) and (max-device-height: 450px) and (min-device-width: 768px) {
  .modal {
    .wrapper {
      max-width: 166vh;
    }
  }
}
