@import "media-query";
@import "color-variables";
@import "fonts";
@import "flex-grid";
@import "base";

@import "components/buttons";
@import "components/badges";
@import "components/header";
@import "components/banner";
@import "components/video";
@import "components/short-demo";
@import "components/short-video";
@import "components/worldwide";
@import "components/floorly";
@import "components/book-demo";
@import "components/looking-for";
@import "components/footer";
@import "components/modal";
@import "components/disclaimer";
@import "components/terms-policy";
@import "components/about-us";
@import "components/gallery";
@import "components/modal-slider";
