.looking-for {
  padding: 100px 0;
  max-width: 940px;
  margin: 0 auto;
  text-align: center;

  &__title {
    margin-bottom: 20px;
  }

  &__text {
    max-width: 620px;
    margin: 0 auto 50px auto;
  }

  &__content {
    &-item {
      display: block;
      width: 100%;
      max-width: 460px;
      padding: 50px 0;
      border: 1px solid var(--color-grey);
      border-radius: 4px;
      transition: box-shadow .2s ease-out;
    }

    &-img {
      margin-bottom: 6px;
    }

    &-title {
      margin-bottom: 10px;
    }

    &-text {
      max-width: 200px;
      margin: 0 auto;
    }
  }
}

@include media-mobile-query() {
  .looking-for {
    padding: 50px 20px;

    &__title {
      max-width: 180px;
      margin: 0 auto 20px auto;
    }

    &__text {
      margin: 0 auto 29px auto;
    }

    &__content {
      &-item {
        margin: 0 auto 18px auto;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-img {
        margin-bottom: 17px;
      }
    }
  }
}
