.short-video {
  cursor: pointer;
  background-color: var(--color-white);
  background-position: center bottom;
  background-size: cover;
  background-image: url("../assets/images/short-video-mock.webp");
  background-repeat: no-repeat;

  width: 100%;
  max-width: 377px;
  height: 213px;
  border-radius: 4px;
  box-shadow: var(--shadow);

  &.--testimonial {
    background-image: url("../assets/images/short-video-testimonial-mock.png");
  }

  &__img {
    height: auto;
    width: 60px;
    margin: auto;
  }
}

@include media-mobile-query() {
  .short-video {
    max-width: 280px;
    height: 158px;
  }
}

@include media-retina-breakpoint() {
  .short-video {
    background-image: url("../assets/images/short-video-mock@2x.webp");

    &.--testimonial {
      background-image: url("../assets/images/short-video-testimonial-mock@2x.png");
    }
  }
}
